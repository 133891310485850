import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Col, Modal, Row, Table, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Spinner from 'react-bootstrap/Spinner';
import '../Asserts/css/rminwards.css'
import Auth from './Auth';
function RmInwards() {
    const orgname = window.localStorage.getItem('org_name')
    const baseURL = window.location.origin;
    const [show, setShow] = useState(false);
    const [load, setload] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [tempTable, setTable] = useState([])
    const [item, setItem] = useState([]);
    const initialValues = {
        rm_name: '',
        invoice: item.reduce((maxId, id) => {
            return maxId > id.invoice ? maxId : id.invoice + 1
        }, 1),
        qty_recd: 0,
        org_name: orgname,
        vendor_name: '',
        units_of_measurement: '',
        creation_date: new Date()

    }
    const [data, setData] = useState(initialValues)
    const [cvdetails, setCvDetails] = useState('');
    const [rmdetails, setRmDetails] = useState([]);
    const [visible, setVisible] = useState(true)
    let urls = [`${baseURL}/api/Info/getRmInwardsDetails/${orgname}`, `${baseURL}/api/Info/getCvInwardsDetails/${orgname}`]
    const handleClose = () => {
        setTable([])
        setData(initialValues)
        setload(true)
        setShow(false)
        setClicked(false)
    };
    const handleShow = (data) => {
        setData(data)
        setShow(true)
    }
    useEffect(() => {
        const requests = urls.map((url) => axios.get(url));
        setData(initialValues)
        setTable([])
        axios.all(requests)
            .then((res) => {
                if (res[0].status === 200) {
                    setRmDetails(res[0].data)
                }
                if (res[1].status === 200) {
                    setCvDetails(res[1].data)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.warn('Vendor or Item Details Not Found', {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
        setload(false)
    }, [])
    console.log(item)
    useEffect(() => {
        //setData(initialValues)
        axios.get(`${baseURL}/api/Transaction/getInwardsDetails/${orgname}`)
            .then((res) => {
                if (res.status === 200) {
                    setItem(res.data)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.info(err.response.data, {
                    position: "bottom-left",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
        setload(false)
    }, [load])
    const onChangeMethod = (name, value) => {
        if (name === 'creation_date') {

            setData({
                ...data, [name]: value
            })
        }
        if (name === 'vendor_name') {
            setData({
                ...data, [name]: parseInt(value)
            })
        }
        if (name === 'invoice' || name === 'qty_recd') {
            if (value !== '') {
                setData({ ...data, [name]: parseInt(value) })
            } else {
                setData({ ...data, [name]: value })
            }
        }
        if (name === 'rm_name') {
            const { units_of_measurement } = rmdetails.find(obj => obj.rm_id === parseInt(value))
            setData({ ...data, [name]: parseInt(value), ['units_of_measurement']: units_of_measurement })
        }

    }
    const deleteRecord = (data) => {
        console.log(data, orgname)
        axios.delete(`${baseURL}/api/Transaction/deleteInwardItem/${data}/${orgname}`)
            .then((res) => {
                if (res.status === 200) {
                    setItem([])
                    toast.success('Successfull.', {
                        position: "bottom-left",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setload(true)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error(err.response.data, {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
    }
    const submitForm = (event) => {
        event.preventDefault();
        axios.post(`${baseURL}/api/Transaction/inwardsInsertion`, tempTable)
            .then((res) => {
                if (res.status === 200) {
                    toast.success('Successfull.', {
                        position: "bottom-left",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    handleClose();
                }
            }).catch((err) => {
                toast.error(err.response.data, {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setClicked(false)
            })
    }
    const tempoValue = () => {
        console.log(data)
        if (Object.values(data).every((value) => value !== 0 && value !== '' && value !== null)) {

            setTable([
                ...tempTable, data
            ])
            setData({
                ...data, ['rm_name']: initialValues.rm_name, ['qty_recd']: initialValues.qty_recd, ['units_of_measurement']: initialValues.units_of_measurement
            })
        } else {
            toast.error(`Few of the Details are not mentioned`, {
                position: "bottom-left",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }
    }
    useEffect(() => {
        if (tempTable.length > 0) {
            setVisible(false)
        }
        else {
            setVisible(true)
        }
    }, [data, tempTable])

    const deleteTableRecord = (index) => {
        const rows = [...tempTable];
        rows.splice(index, 1);
        setTable(rows);
    }
    const displayValue = (i) => {
        const { name_of_the_item } = rmdetails.find(obj => obj.rm_id === parseInt(i))
        return name_of_the_item
    }
    const displayCvValue = (i) => {
        const { name } = cvdetails.find(obj => obj.cv_id === parseInt(i))
        return name
    }
    return (
        <div>
            <Table striped bordered hover className='table container'>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Invoice</th>
                        <th>RM Name</th>
                        <th>Quantity Received</th>
                        <th>Vendor Name</th>
                        <th>{`Dt.Of Entry(YYYY-MM-DD)`}</th>
                        <th><Link><span className="material-symbols-outlined" onClick={() => { handleShow(initialValues) }}>
                            library_add
                        </span></Link></th>

                    </tr>
                </thead>
                <tbody>
                    {item && item.map((i, index) => (
                        <tr key={i.rminward_id}>
                            <td>{index + 1}</td>
                            <td>{i.invoice}</td>
                            <td>{i.rm_name}</td>
                            <td>{`${i.qty_recd}`}<span>{i.units_of_measurement}</span></td>
                            <td>{i.vendor_name}</td>
                            <td>{i.creation_date.split('T')[0]}</td>
                            <td>
                                {/* <Link><span className="material-symbols-outlined" onClick={() => { handleShow(i); setType('edit') }}>
                                    edit_note
                                </span></Link> */}
                                <Link><span className="material-symbols-outlined" onClick={() => deleteRecord(i.rminward_id)}>
                                    delete_forever
                                </span></Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table >
            <Modal show={show} onHide={handleClose} size='lg'
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <b style={{ color: "red", fontSize: 25 }}>RM Inwards</b>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Label>Receipt Number</Form.Label>
                                <Form.Control placeholder="Invoice Number" value={data.invoice}
                                    name='invoice' type='number' required
                                    disabled={!visible}
                                    onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }} />
                            </Col>
                            <Col>
                                <Form.Label>Date</Form.Label>
                                <Form.Group>
                                    <DatePicker
                                        selected={data.creation_date ? new Date(data.creation_date) : new Date()}
                                        maxDate={new Date() - 1} required
                                        disabled={!visible}
                                        onChange={(date) => {
                                            onChangeMethod('creation_date', date)
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Label>Vendor</Form.Label>
                                <Form.Select
                                    name='vendor_name' required
                                    value={data.vendor_name} disabled={!visible}
                                    onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }}>
                                    {cvdetails && <option value=''>Select the option</option>}
                                    {cvdetails ?
                                        cvdetails.map((i) => (
                                            <option key={i.cv_id}
                                                value={i.cv_id}>{i.name}</option>
                                        ))
                                        : <option>No Details of Vendor</option>}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>Raw Material</Form.Label>
                                <Form.Select
                                    name='rm_name'
                                    required
                                    value={data.rm_name}
                                    onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }}>
                                    {rmdetails && <option value=''>Select the option</option>}
                                    {rmdetails ?
                                        rmdetails.map((i) => (
                                            <option key={i.rm_id}
                                                value={i.rm_id}>{i.name_of_the_item}</option>
                                        ))
                                        : <option>No Details of Raw Material</option>}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label>Quantity Received</Form.Label>
                                <Form.Control placeholder="Quantity Received"
                                    name='qty_recd' type='number' required value={data.qty_recd}
                                    // onKeyUp='false' onKeyDown={false}
                                    onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }} />
                            </Col>
                            <Col>
                                <Form.Label>UOM</Form.Label>
                                <Form.Control disabled
                                    name='units_of_measurement' type='text' required value={data.units_of_measurement} />
                            </Col>
                        </Row>
                    </Form>
                    {
                        tempTable.length > 0 &&
                        <div className='temptable'>
                            <Table striped bordered hover className='table container'>
                                <thead>
                                    <tr>
                                        <th>RM Name</th>
                                        <th>Quantity Received</th>
                                        <th>Vendor Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tempTable.map((i, index) => (
                                            <tr key={index}>
                                                <td>{displayValue(i.rm_name)}</td>
                                                <td>{i.qty_recd}</td>
                                                <td>{displayCvValue(i.vendor_name)}</td>
                                                <td><span className="material-symbols-outlined" onClick={() => deleteTableRecord(index)}>
                                                    delete_forever
                                                </span></td>
                                            </tr>

                                        ))
                                    }
                                </tbody>
                            </Table>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => tempoValue()}>Add</Button>

                    {/* <Button variant='secondary' onClick={handleClose}>Close</Button> */}
                    {clicked ? <Spinner animation="border" variant='primary' />
                        : <Button variant='primary' disabled={visible} onClick={(event) => submitForm(event)}>Save</Button>}
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </div >
    )
}

export default Auth(RmInwards)
