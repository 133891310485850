import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Menubar from './components/Menubar';
import ItemComponent from './components/ItemComponent';
import MasterCustomer from './components/MasterCustomer';
import RmInwards from './components/RmInwards';
import RmIssues from './components/RmIssues';
import FgDetails from './components/FgDetails';
import Dashboard from './components/Dashboard';
import SalesInv from './components/SalesInv';
import PrintablePage from './components/PrintablePage';
import Reports from './components/Reports';
import PaymentReceipt from './components/PaymentReceipt';
import CreateUser from './components/CreateUser';
import BatchProcess from './components/BatchProcess';
function App() {
  return (
    <>
      < Routes >
        <Route path='/' exact element={<Login />} />
        <Route path='/print' exact element={<PrintablePage />} />
        <Route path='/menubar' exact element={<Menubar />} >
          <Route path='/menubar/create-user' element={<CreateUser />} />
          <Route path='/menubar/master/item' element={<ItemComponent />} />
          <Route path='/menubar/master/cvdetails' element={<MasterCustomer />} />
          <Route path='/menubar/master/batchprocess' element={<BatchProcess />} />
          <Route path='/menubar/transactions/rminwards' element={<RmInwards />} />
          <Route path='/menubar/transactions/salesinfo' element={<SalesInv />} />
          <Route path='/menubar/transactions/rmissues' element={<RmIssues />} />
          <Route path='/menubar/transactions/fgdetails' element={<FgDetails />} />
          <Route path='/menubar/dashboard' element={<Dashboard />} />
          <Route path='/menubar/reports' element={<Reports />} />
          <Route path='/menubar/payment-receipt' element={<PaymentReceipt />} />
        </Route>
        {/* <Route path='/' element={<SalesInv />} /> */}
      </Routes >
    </>
  );
}

export default App