import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { UserSlice } from "./slices/UserSlice";
import { ItemMasterSlice } from "./slices/ItemMasterSlice";
// to access this user use selector
const reducerArry = combineReducers({
    user: UserSlice.reducer,
    itemmaster: ItemMasterSlice.reducer
})
const store = configureStore({

    reducer: reducerArry
})

export default store