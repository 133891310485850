import { createSlice } from "@reduxjs/toolkit";
const ItemMasterSlice = createSlice({
    name: 'itemSlice',
    initialState: [],
    reducers: {
        addItem(state, action) {
            state.push(action.payload)
        },
        clearItem(state, action) {
            state.push(action.payload)
        },
        updateQty(state, action) {
            const { rm_name, qty_available } = action.payload
            const result = state.find((i) => i.rm_id === parseInt(rm_name))
            if (result) {
                result.qty_available = qty_available
            }
        }
    }
})

export { ItemMasterSlice }
export const { addItem, clearItem, updateQty } = ItemMasterSlice.actions