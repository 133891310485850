import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react'
import { Col, Row, Table, Form, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation } from 'react-router-dom';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { ToastContainer, toast } from 'react-toastify';
//eslint-disable-next-line
import html2pdf from 'html2pdf.js';
import '../Asserts/css/reports.css'
import Auth from './Auth';
function Reports() {
    const tableRef = useRef();
    const baseURL = window.location.origin;
    const orgname = window.localStorage.getItem('org_name')
    const location = useLocation();
    const [cust_cv, setcvCus] = useState(null)
    const [visible, setVisible] = useState(true)
    const queryParams = new URLSearchParams(location.search)
    const value = queryParams.get('value')
    let urls = [`${baseURL}/api/Transaction/getRmIssueDetails/${orgname}`, `${baseURL}/api/Transaction/getFgReceivedDetails/${orgname}`, `${baseURL}/api/Transaction/getSalesinfo/${orgname}`, `${baseURL}/api/Info/getCustomerDetails/${orgname}`, `${baseURL}/api/Transaction/getInwardsDetails/${orgname}`, `${baseURL}/api/Info/getCvInwardsDetails/${orgname}`]
    const [rmdata, setRmData] = useState()
    const [item, setItem] = useState()
    const [startDate, setStartDate] = useState(null);
    const [cvname, setCvDetails] = useState();
    const [endDate, setEndDate] = useState(null);
    const [paymt, setPaymt] = useState('ALL');
    const onChange = (dates) => {
        const [start, end] = dates;
        // console.log(start, end)
        setStartDate(start);
        setEndDate(end);
        modifyTable(start, end);
    };
    useEffect(() => {
        console.log(value)
        setStartDate(null)
        setEndDate(null)
        setcvCus(null)
        setVisible(true)
        if (value === 'rmissues') {
            axios.get(urls[0])
                .then((res) => {
                    if (res.status === 200) {
                        setRmData(res.data)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    toast.error(err.response.data, {
                        position: "bottom-left",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                })
        }
        else if (value === 'fgdetails') {
            axios.get(urls[1])
                .then((res) => {
                    if (res.status === 200) {
                        setRmData(res.data)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    toast.error(err.response.data, {
                        position: "bottom-left",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                })
        }
        else if (value === 'salesinfo') {
            axios.get(urls[2])
                .then((res) => {
                    if (res.status === 200) {
                        setRmData(res.data)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    toast.error(err.response.data, {
                        position: "bottom-left",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                })
            axios.get(urls[3])
                .then((res) => {
                    if (res.status === 200) {
                        setCvDetails(res.data)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    toast.error(err.response.data, {
                        position: "bottom-left",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                })
        }
        else if (value === 'rminwards') {
            axios.get(urls[5])
                .then((res) => {
                    if (res.status === 200) {
                        setCvDetails(res.data)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    toast.error(err.response.data, {
                        position: "bottom-left",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                })
            axios.get(urls[4])
                .then((res) => {
                    if (res.status === 200) {
                        setRmData(res.data)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    toast.error(err.response.data, {
                        position: "bottom-left",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                })
        }
    }, [value])
    useEffect(() => {
        if (paymt === 'ALL' && value === 'salesinfo') {
            setItem(rmdata)
        }
        else if (paymt === 'CREDIT' || paymt === 'CASH' || paymt === 'UPI') {
            const result = rmdata.filter(item => {
                return item.payment_type === paymt
            })
            setItem(result)
        }
    }, [paymt, value])
    const modifyTable = (startDate, endDate) => {
        console.log('table', startDate, endDate)
        if (startDate !== null && endDate !== null) {
            const filterDate = rmdata.filter(item => {
                return new Date(item.creation_date) >= startDate && new Date(item.creation_date) <= endDate
            })
            console.log(filterDate)
            if (filterDate.length === 0) {
                setVisible(true)
                toast.error('No Details Found with Selected Dates', {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                setItem(filterDate)
                setVisible(false)
            }

            console.log(rmdata, '1')
            console.log(filterDate, 'date')
        }
        else {
            setVisible(true)
        }
    }
    console.log(cvname, '5')
    console.log(rmdata, '4')
    const onChangeMethod = (value) => {
        // const name1 = cvname.filter(obj1 => rmdata.some(obj2 => obj2.id === obj1.id));
        const { cv_id } = cvname.find(obj => obj.cv_id === parseInt(value))
        setcvCus(cv_id)
        // console.log(name1)
        if (parseInt(value) !== 0) {
            const filterByCustomer = rmdata.filter(item => {
                return item.cv_id === parseInt(value)
            })
            if (filterByCustomer.length === 0) {
                setVisible(true)
                toast.error('No Details Found with Selected Customer', {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                setItem(filterByCustomer)
                setVisible(false)
            }
        }
    }
    const convertToPDF = () => {
        const element = tableRef.current
        html2pdf()
            .set({ html2canvas: { scale: 5 } })
            .from(element)
            .save();
    }
    const printTable = () => {
        window.print()
    }
    const clearDate = (e) => {
        e.preventDefault()
        setStartDate(null)
        setEndDate(null)
        setcvCus(null)
        setVisible(true)
    }
    return (
        <div className='report-class-body'>
            <div className='report-body'>
                <div className='operator'>
                    <div>
                        {(value === 'salesinfo' || value === 'rminwards') && <div className='print-hide'>
                            <Col>
                                <Row>
                                    <Form.Label>Select Customer</Form.Label>
                                    <Form.Select
                                        name='cvname'
                                        value={cust_cv !== null ? cust_cv : ''}
                                        // disabled={data.cv_name !== 0 ? true : false}
                                        onChange={(e) => { onChangeMethod(e.target.value) }}>
                                        {cvname && <option value='' defaultChecked>Select the option</option>}
                                        {cvname ?
                                            cvname.map((i) => (
                                                <option key={i.cv_id}
                                                    value={i.cv_id}>{i.name}</option>
                                            ))
                                            : <option>No Details of Customer</option>}
                                    </Form.Select>
                                </Row>
                            </Col>
                        </div>}
                        <span className='print-hide'>Select Date:</span>
                        <DatePicker
                            className='print-hide'
                            disabled={rmdata !== [] ? false : true}
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date() - 1}
                            selectsRange
                        // inline
                        />
                        <Link className='print-hide' href='#' onClick={(e) => clearDate(e)}>Clear</Link>
                    </div>
                    {/* <Button className='print-hide button-go' onClick={() => modifyTable()} disabled={item !== [] ? false : true}>Go</Button> */}
                </div>
                {
                    value === 'salesinfo' && !visible &&
                    <div>
                        <Col>
                            <Form.Label className='print-hide'>Payment Type</Form.Label><br />
                            <Form.Check className='print-hide'
                                inline
                                label="ALL"
                                name="payment_type"
                                defaultChecked
                                value="ALL"
                                onChange={(e) => { setPaymt(e.target.value) }}
                                type='radio'
                            />
                            <Form.Check className='print-hide'
                                inline
                                label="CASH"
                                name="payment_type"
                                value="CASH"
                                onChange={(e) => { setPaymt(e.target.value) }}
                                type='radio'
                            />
                            <Form.Check className='print-hide'
                                inline
                                label="CREDIT"
                                name="payment_type"
                                value="CREDIT"
                                onChange={(e) => { setPaymt(e.target.value) }}
                                type='radio'
                            />
                            <Form.Check className='print-hide'
                                inline
                                label="UPI"
                                name="payment_type"
                                value="UPI"
                                onChange={(e) => { setPaymt(e.target.value) }}
                                type='radio'
                            />
                        </Col>
                    </div>
                }
                {!visible && <div ref={tableRef}>
                    <span className='title'><b>{value.toUpperCase()}</b></span>
                    {value === 'rmissues' &&
                        <>
                            <Table id="table-to-xls" striped bordered hover className='print-container'>
                                <thead>
                                    <tr>
                                        <th>S.NO</th>
                                        <th>Invoice</th>
                                        <th>{`Date(YYYY-MM-DD)`}</th>
                                        <th>RM Name</th>
                                        <th>Quantity Issued</th>
                                        <th>Issued To</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {item && item.map((i, index) => (
                                        <tr key={index}>
                                            <td className='index'>{index + 1}</td>
                                            <td className='invoice'>{i.invoice}</td>
                                            <td className='date'>{i.creation_date.split('T')[0]}</td>
                                            <td className='name'>{i.rm_name}</td>
                                            <td className='recd'>{`${i.qty_recd}`}<span>{i.units_of_measurement}</span></td>
                                            <td className='issue_to'>{i.issued_to}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table >
                        </>
                    }
                    {value === 'fgdetails' &&
                        <>
                            {/* <span className='title'><b>{value.toUpperCase()}</b></span> */}
                            <Table id="table-to-xls" striped bordered hover className='print-container'>
                                <thead>
                                    <tr>
                                        <th>S.NO</th>
                                        <th>Invoice</th>
                                        <th>{`Date(YYYY-MM-DD)`}</th>
                                        <th>FG Name</th>
                                        <th>Quantity Received</th>
                                        <th>Recd By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {item && item.map((i, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{i.invoice}</td>
                                            <td>{i.creation_date.split('T')[0]}</td>
                                            <td>{i.fg_name}</td>
                                            <td>{`${i.qty_recd}`}<span>{i.units_of_measurement}</span></td>
                                            <td>{i.recd_by}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table >
                        </>
                    }
                    {
                        value === 'salesinfo' &&
                        <div>
                            <div>
                                <Table id="table-to-xls" striped bordered hover className='print-container'>
                                    <thead>
                                        <tr>
                                            <th>S.NO</th>
                                            <th>Invoice</th>
                                            <th>{`Date(YYYY-MM-DD)`}</th>
                                            <th>Customer Name</th>
                                            <th>FG Name</th>
                                            <th>Quantity Supplied</th>
                                            <th>Payment Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {item && item.map((i, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{i.invoice}</td>
                                                <td>{i.creation_date.split('T')[0]}</td>
                                                <td>{i.customer_name}</td>
                                                <td>{i.fg_name}</td>
                                                <td>{`${i.qty_supplied}`}<span>{i.uom}</span></td>
                                                <td>{i.payment_type}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table >
                            </div>
                        </div>
                    }
                    {value === 'rminwards' &&
                        <>
                            <Table id="table-to-xls" striped bordered hover className='print-container'>
                                <thead>
                                    <tr>
                                        <th>S.NO</th>
                                        <th>Invoice</th>
                                        <th>Vendor Name</th>
                                        <th>{`Date(YYYY-MM-DD)`}</th>
                                        <th>RM Name</th>
                                        <th>Quantity Issued</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {item && item.map((i, index) => (
                                        <tr key={index}>
                                            <td className='index'>{index + 1}</td>
                                            <td className='invoice'>{i.invoice}</td>
                                            <td className='name'>{i.vendor_name}</td>
                                            <td className='date'>{i.creation_date.split('T')[0]}</td>
                                            <td className='name'>{i.rm_name}</td>
                                            <td className='recd'>{`${i.qty_recd}`}<span>{i.units_of_measurement}</span></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table >
                        </>
                    }
                </div>}
                <div className='option'>
                    {!visible && <div className='spacing'><Button disabled={visible} className='print-hide' onClick={() => convertToPDF()}>Convert to PDF</Button></div>}
                    {!visible && <div className='spacing'><Button disabled={visible} className='print-hide' onClick={printTable}>Print page</Button></div>}
                    {!visible && <div className='spacing'>
                        {/* <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button"
                            table="table-to-xls"
                            filename="tablexls"
                            sheet="tablexls"
                            buttonText="Download as XLS" /> */}
                        <DownloadTableExcel
                            filename="users table"
                            sheet="users"
                            currentTableRef={tableRef.current}>
                            <button> Export excel </button>
                        </DownloadTableExcel>
                    </div>}
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Auth(Reports)
