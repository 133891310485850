import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Auth from './Auth'
import '../Asserts/css/dashboard.css'
import { BarChart, Bar, Line, XAxis, YAxis, Tooltip, Legend, LineChart, CartesianGrid } from 'recharts';
import { Table } from 'react-bootstrap';

function Dashboard() {
    const baseURL = window.location.origin;
    const [data, setData] = useState([]);
    const { user_type } = JSON.parse(window.localStorage.getItem('user_details'))

    const orgname = window.localStorage.getItem('org_name')

    useEffect(() => {
        if (user_type) {
            axios.get(`${baseURL}/api/Info/getAllCustomers`)
                .then((res) => {
                    if (res.status === 200) {
                        setData(res.data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            axios.get(`${baseURL}/api/Info/getValues/${orgname}`)
                .then((res) => {
                    if (res.status === 200) {
                        setData(res.data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, []);

    return (
        <>
            {
                data.length > 0 && !user_type && (
                    <>
                        <div className='linechart'>
                            <div className='item-chart'>
                                <h6 className='chartlabel'>Stock Available</h6>
                                <LineChart width={500} height={500} data={data}>
                                    <Line dataKey="qty_available" fill="#8884d8" type='monotone' stroke='#F44236' strokeWidth={3} />
                                    <XAxis dataKey="name_of_the_item" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <CartesianGrid stroke='#ccc' />

                                </LineChart>
                            </div>
                        </div>
                    </>
                )
            }
            {
                data.length > 0 && user_type && (
                    <>
                        <Table striped bordered hover className='container'>
                            <thead>
                                <tr>
                                    <th>S.NO</th>
                                    <th>Org Name</th>
                                    <th>Name</th>
                                    <th>Mail</th>
                                    <th>Mobile No</th>
                                    <th>GST No</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data && data.map((i, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{i.org_name}</td>
                                            <td>{i.person_name}</td>
                                            <td>{i.mailid}</td>
                                            <td>{i.mobile_number}</td>
                                            <td>{i.gst_no}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>

                    </>
                )
            }
            <span>{data.length === 0 && <p>No Data Found</p>}</span>
        </>
    );
}

export default Auth(Dashboard);