import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useNavigate } from 'react-router-dom'
import { Navbar, Container, Nav, NavDropdown, Image } from 'react-bootstrap';
import '../Asserts/css/menubar.css'
import Auth from './Auth';
function Menubar() {
    const navigate = useNavigate();
    //const user_type = window.localStorage.getItem("user_type");
    const { user_type } = JSON.parse(window.localStorage.getItem('user_details'))
    const reset = (event) => {
        event.preventDefault();
        window.sessionStorage.clear();
        window.localStorage.clear();
        navigate('/', { replace: true })
    }
    return (
        <div>
            <Navbar className='print-hide' bg="light" expand="lg" collapseOnSelect>
                <Container>
                    <Navbar.Brand as={Link} to={'/menubar/dashboard'}>
                        {/* <Image src='https://cdn3.iconfinder.com/data/icons/glypho-social-and-other-logos/64/logo-facebook-512.png'
                            height="30" className="d-inline-block align-top" alt="Logo" /> */}
                        Pakasala
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        {user_type &&
                            <Nav className='ms'>
                                <Nav.Link as={Link} to={'/menubar/create-user'}>Create User</Nav.Link>
                            </Nav>
                        }
                        {!user_type && <Nav className="ms">
                            <NavDropdown title="Master" id="collasible-nav-dropdown">
                                <NavDropdown.Item as={Link} to={'/menubar/master/item'}>Item</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/menubar/master/cvdetails'}>CV Details</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/menubar/master/batchprocess'}>Batch Process</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Transactions" id="collasible-nav-dropdown">
                                {/* /menubar/transactions/rmissues */}
                                <NavDropdown.Item as={Link} to={'/menubar/transactions/rminwards'}>Inwards</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/menubar/transactions/rmissues'}>Issues</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/menubar/transactions/fgdetails'}>FG Received</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/menubar/transactions/salesinfo'}>Sales Invoice</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/menubar/payment-receipt'}>Payment Receipt</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Reports" id="collasible-nav-dropdown">
                                {/* /menubar/transactions/rmissues */}
                                <NavDropdown.Item as={Link} to={`/menubar/reports?value=${'rmissues'}`}>Issues</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`/menubar/reports?value=${'rminwards'}`}>Inwards</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`/menubar/reports?value=${'fgdetails'}`}>FG Received</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`/menubar/reports?value=${'salesinfo'}`}>Sales Invoice</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>}
                        <Nav className="ms-auto">
                            <NavDropdown title={<span className="material-symbols-outlined">
                                person
                            </span>} id="basic-nav-dropdown">
                                <NavDropdown.Item as={Link} to={'/menubar/dashboard'}>Dashboard</NavDropdown.Item>
                                {!user_type && <NavDropdown.Item href="#">Profile</NavDropdown.Item>}
                                {!user_type && <NavDropdown.Item href="#">Settings</NavDropdown.Item>}
                                <NavDropdown.Item onClick={reset}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Outlet />
        </div>)
}

export default Auth(Menubar)