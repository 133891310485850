import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { addUser } from '../store/slices/UserSlice';
import '../Asserts/css/login.css'
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';

function Login() {
  window.localStorage.clear();
  const loginDetails = {
    "type": "",
    "username": "",
    "password": ""
  }
  const baseURL = window.location.origin;
  const [clicked, setClicked] = useState(false)
  const [details, setDetails] = useState(loginDetails)
  const [digit, setDigit] = useState(50)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setClicked(true)
    if (details.username !== loginDetails.username && details.password !== loginDetails.password && details.type !== loginDetails.type) {
      await axios.post(`${baseURL}/api/User/userLoginDetails`, details)
        .then((res) => {
          //console.log(res.data[0] === [])
          if (res.status === 200) {
            dispatch(addUser(res.data[0]));
            //window.sessionStorage.setItem("org_name", res.data[0].org_name)
            window.localStorage.setItem("org_name", res.data[0].org_name)
            window.localStorage.setItem("user_details", JSON.stringify(res.data[0]))
            navigate('/menubar/dashboard', { replace: true, })
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response.data, {
            position: "bottom-left",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setClicked(false)
          setDetails(loginDetails)
          setDigit(50)
        })
    }
  }
  const handleChange = (name, value) => {
    var regex = /^[a-zA-Z]+$/;
    var regex1 = /^[0-9]*$/;
    if (name === 'username') {
      if (value.charAt(0).match(regex)) {
        setDetails({
          ...details, type: 'mailid', username: value
        });
        setDigit(50);
      } else {
        if (regex1.test(value)) {
          setDetails({
            ...details, type: 'mobile_number', username: value
          });
          setDigit(10);
        }
      }
    }
    if (name === 'password') {
      setDetails({
        ...details, password: value
      })
    }
  }
  return (
    <div className='main-login'>
      <div className='container-login'>
        <div className='left-side'>
          <span className='login-title'>Pakasala</span>
          <p className='login-description'>
            Welcome to our all-in-one invoicing and accounting solution! Our site provides a comprehensive platform for managing your business's financial transactions and records with ease and efficiency.
          </p>
        </div>
        <div className='right-side'>
          <div className='login-form'>
            <Form onSubmit={(e) => handleSubmit(e)}>
              <label className='login-title-label'>Login</label>
              <Form.Group controlId="formBasicEmail" className='form-group'>
                <Form.Label className='login-label'><span>Mail/Phone Number</span></Form.Label>
                <Form.Control className='login-password'
                  autoCorrect='off' autoComplete='off' autoSave='off'
                  value={details.username} required maxLength={digit}
                  type="text" placeholder="Mail/Phone Number"
                  name='username' onChange={(event) => { handleChange(event.target.name, event.target.value) }}
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword" className='form-group'>
                <Form.Label className='login-label'><span>Password</span></Form.Label>
                <Form.Control className='login-password'
                  type="password" placeholder="Password"
                  name='password' value={details.password} required
                  onChange={(event) => { handleChange(event.target.name, event.target.value) }}
                />
              </Form.Group>
              {clicked ? <Spinner animation="border" variant='primary' /> :
                <Button className='login-button' variant="primary" type="submit">Submit</Button>}
            </Form>
          </div>
        </div >
        <ToastContainer limit={1} />
      </div>
    </div>
  );
}
export default Login;