import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Col, Modal, Row, Table, Form, Button, Collapse } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Spinner from 'react-bootstrap/Spinner';
function BatchProcess() {
    const orgname = window.localStorage.getItem('org_name')
    const baseURL = window.location.origin;
    let urls = [`${baseURL}/api/Info/getRmFgDetails/${orgname}`,
    `${baseURL}/api/Info/getRmInwardsDetails/${orgname}`,
    `${baseURL}/api/Info/getBpFgCustomers/${orgname}`,
    `${baseURL}/api/Info/getBpRmCustomers/${orgname}`]
    const [show, setShow] = useState(false);
    const [rmshow, setRmShow] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [tempTable, setTable] = useState([]);
    const [fgDetails, setFgDetails] = useState([]); // master FG details
    const [BpFgDetails, setBpFgDetails] = useState([]); // Batch process FG details
    const [BpRmDetails, setBpRmDetails] = useState([]); // Batch process RM details
    const [rmDetails, setRgDetails] = useState([]); // master RM details
    const [visible, setVisible] = useState(true)
    const initialValues = {
        creation_date: new Date(),
        batch_no: '',
        fg_name: '',
        batch_size: 0,
        rm_name: '',
        units_of_measurement: '',
        qty_recd: 0,
        org_name: orgname
    }
    const [data, setData] = useState(initialValues)
    useEffect(() => {
        const requests = urls.map((url) => axios.get(url));
        setTable([])
        axios.all(requests)
            .then((res) => {
                if (res[0].status === 200) {
                    setFgDetails(res[0].data)
                }
                if (res[1].status === 200) {
                    setRgDetails(res[1].data)
                }
                if (res[2].status === 200) {
                    setBpFgDetails(res[2].data)
                }
                if (res[3].status === 200) {
                    setBpRmDetails(res[3].data)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error(err.response.data, {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
    }, [])
    useEffect(() => {
        if (tempTable.length > 0) {
            setVisible(false)
        }
        else {
            setVisible(true)
        }
    }, [data, tempTable])
    const handleClose = () => {
        setTable([])
        setData(initialValues)
        setShow(false)
        setRmShow(() => ({
            show: false,
            data: null
        }))
        setClicked(false)
    };
    const handleShow = () => {
        setShow(true)

    }
    const handleRmShow = (data) => {
        setRmShow(() => ({
            show: true,
            data: data
        }))
    }
    const onChangeMethod = (name, value) => {
        const getRmId = (value) => {
            const recd = rmDetails.find(obj => obj.rm_id === parseInt(value))
            return recd ? recd : 0
        }
        if ((name === 'batch_size' || name === 'fg_name' || name === 'qty_recd') && value != '') {
            setData({
                ...data, [name]: parseInt(value)
            })
        }
        else if (name === 'rm_name' && value != '') {
            const { units_of_measurement } = getRmId(value)
            setData({
                ...data, [name]: parseInt(value), ['units_of_measurement']: units_of_measurement
            })
        }
        else {
            setData({
                ...data, [name]: value
            })
        }
    }
    const tempoValue = () => {
        if (Object.values(data).every((value) => value !== '' && value !== 0)) {
            setTable([
                ...tempTable, data
            ])
            setData({
                ...data, ['rm_name']: initialValues.rm_name, ['units_of_measurement']: initialValues.units_of_measurement, ['qty_recd']: initialValues.qty_recd
            })
        } else {
            toast.error(`Few of the Details are Null`, {
                position: "bottom-left",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }
    }
    const displayValue = (i) => {
        const { name_of_the_item } = rmDetails.find(obj => obj.rm_id === parseInt(i))
        return name_of_the_item
    }
    const deleteTableRecord = (index) => {
        const rows = [...tempTable];
        rows.splice(index, 1);
        setTable(rows);
    }
    const submitForm = (e) => {
        e.preventDefault();
        console.log(tempTable)
        axios.post(`${baseURL}/api/Transaction/batchInsertion`, tempTable)
            .then((res) => {
                if (res.status === 200) {
                    toast.success('Successfull.', {
                        position: "bottom-left",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    handleClose();
                }
            }).catch((err) => {
                toast.error(err.response.data, {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setClicked(false)
            })
    }
    return (
        <>
            <Table striped bordered hover className='table container'>
                <thead>
                    <tr>
                        <th>S.NO</th>
                        <th>Batch Number</th>
                        <th>Batch Size</th>
                        <th>FG Name</th>
                        <th>UOM</th>
                        <th>
                            <Link><span className="material-symbols-outlined" onClick={() => handleShow()}>
                                library_add
                            </span></Link>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        BpFgDetails && BpFgDetails.map((i, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{i.batch_no}</td>
                                <td>{i.batch_size}</td>
                                <td>{i.name_of_the_item}</td>
                                <td>{i.units_of_measurement}</td>
                                <td>
                                    <Link>
                                        <span onClick={() => handleRmShow(i.fgbp_id)}>View</span>
                                    </Link>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            {/* Below Modal for display */}
            <Modal show={rmshow.show} onHide={handleClose}
                size="ms"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <b style={{ color: "red", fontSize: 25 }}>RM Details</b>
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover className='table container'>
                        <thead>
                            <tr>
                                <th>S.NO</th>
                                <th>Batch Number</th>
                                <th>RM Name</th>
                                <th>UOM</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                BpRmDetails && BpRmDetails
                                    .filter((item) => item.fgbp_id === parseInt(rmshow.data))
                                    .map((i, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{rmshow.data}</td>
                                            <td>{i.name_of_the_item}</td>
                                            <td>{i.uom}</td>
                                            <td>{!i.isrmactive ? 'Completed' : 'Not Completed'}</td>
                                        </tr>
                                    ))
                            }
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
            {/* Below Modal for Insertion */}
            <Modal show={show} onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <b style={{ color: "red", fontSize: 25 }}>Batch Process</b>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className='col-12'>
                            <Col className='col-5'>
                                <Form.Label>Date</Form.Label>
                                <Form.Group>
                                    <DatePicker
                                        selected={new Date()}
                                        disabled={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='offset-2'>
                                <Form.Label>Batch Number</Form.Label>
                                <Form.Control placeholder='Enter Batch Number'
                                    autoComplete='off' autoSave='off'
                                    disabled={tempTable.length > 0 ? true : false}
                                    value={data.batch_no} name='batch_no'
                                    type='search' list='list'
                                    required maxLength={12}
                                    onChange={(e) => onChangeMethod(e.target.name, e.target.value.toUpperCase())}
                                />
                                <datalist id="list">
                                    {BpFgDetails.map((d, index) => <option key={index} value={d.batch_no} />)}
                                </datalist>
                            </Col>
                        </Row>
                        <Row className='col-12'>
                            <Col className='col-5'>
                                <Form.Label>FG Name</Form.Label>
                                <Form.Select
                                    name='fg_name'
                                    required
                                    value={data.fg_name}
                                    disabled={tempTable.length > 0 ? true : false}
                                    onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }}>
                                    {fgDetails && <option value={0}>Select the option</option>}
                                    {fgDetails ?
                                        fgDetails.map((i) => (
                                            <option key={i.rm_id}
                                                value={i.rm_id}>{i.name_of_the_item}</option>
                                        ))
                                        : <option>No Details of Finished Goods</option>}
                                </Form.Select>
                            </Col>
                            <Col className='offset-2'>
                                <Form.Label>Batch Quantity</Form.Label>
                                <Form.Control placeholder='Batch Quantity'
                                    name='batch_size' autoComplete='off' autoSave='off' type='text'
                                    disabled={tempTable.length > 0 ? true : false}
                                    value={data.batch_size}
                                    required
                                    onChange={(e) => onChangeMethod(e.target.name, e.target.value)}
                                />
                            </Col>
                        </Row>
                        <div style={{ height: '1px', backgroundColor: 'black', marginTop: '10px', marginBottom: '10px' }}></div>
                        <span><b style={{ fontSize: 18 }}>RM Required</b></span>
                        <Row>
                            <Col>
                                <Form.Label>RM Name</Form.Label>
                                <Form.Select
                                    name='rm_name'
                                    required
                                    value={data.rm_name}
                                    onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }}>
                                    {rmDetails && <option value={0}>Select the option</option>}
                                    {rmDetails ?
                                        rmDetails.map((i) => (
                                            <option key={i.rm_id}
                                                value={i.rm_id}>{i.name_of_the_item}</option>
                                        ))
                                        : <option>No Details of Raw Material</option>}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label>UOM</Form.Label>
                                <Form.Control disabled
                                    name='units_of_measurement' type='text' required value={data.units_of_measurement} />
                            </Col>
                            <Col>
                                <Form.Label>Quantity Required</Form.Label>
                                <Form.Control placeholder='Quantity Required'
                                    name='qty_recd' autoComplete='off' autoSave='off' type='text'
                                    value={data.qty_recd}
                                    required
                                    onChange={(e) => onChangeMethod(e.target.name, e.target.value)}
                                />
                            </Col>
                        </Row>
                    </Form>
                    {
                        tempTable.length > 0 &&
                        <div className='temptable'>
                            <Table striped bordered hover className='table container'>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Batch No</th>
                                        <th>RM Name</th>
                                        <th>UOM</th>
                                        <th>Quantity Required</th>
                                        <th>Remove</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tempTable.map((i, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{i.batch_no}</td>
                                                <td>{displayValue(i.rm_name)}</td>
                                                <td>{i.units_of_measurement}</td>
                                                <td>{i.qty_recd}</td>
                                                <td><span className="material-symbols-outlined" onClick={() => deleteTableRecord(index)}>
                                                    delete_forever
                                                </span></td>
                                            </tr>

                                        ))
                                    }
                                </tbody>
                            </Table>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={tempoValue}>Add</Button>
                    {clicked ? <Spinner animation="border" variant='primary' />
                        : <Button variant='primary' disabled={visible} onClick={(event) => submitForm(event)}>Save</Button>}
                </Modal.Footer>
            </Modal >
            <ToastContainer />
        </>
    )
}

export default BatchProcess
