import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Auth = (Component) => {

    const AuthenticatedComponent = (props) => {
        const navigate = useNavigate();
        const isAuthenticated = !!window.localStorage.getItem("org_name")
        useEffect(() => {
            if (!isAuthenticated) {
                console.log(isAuthenticated)
                navigate("/", { replace: true })
            }
        }, [isAuthenticated, navigate])
        return isAuthenticated ? (<><Component {...props} /></>) : null
    }
    return AuthenticatedComponent;
}

export default Auth