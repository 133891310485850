import React, { useEffect, useState } from 'react';
import '../Asserts/css/printable.css';
import { Button } from 'react-bootstrap';
import Auth from './Auth';
function PrintablePage() {
    const sales = JSON.parse(window.sessionStorage.getItem('sales'))
    const user = JSON.parse(window.localStorage.getItem('user_details'))

    const headers = ['S.NO', 'Item', 'Hsncode', 'Qty', 'Rate', 'Amount', 'Payment Type'];
    const totalAmount = () => {
        let total = 0;
        sales?.map((row) => {
            total = row.total_price + total
        })
        return total
    }
    return (
        <div className='box'>
            <div className='title'><b>Invoice</b></div>
            <div className='horizontal-direction'>
                <div className='cdetails'>
                    <span><b>{user?.org_name.toUpperCase()}</b></span>
                    <span>{user?.address}</span><br />
                    <span><b>Gst NO:</b>{user?.gst_no}</span>
                </div>
            </div>
            <hr />
            <div className='cstdetails'>
                <div className='details'>
                    <span><b><u>Customer Details:</u></b></span>
                    <span>
                        {sales !== null && sales[0]?.cname}
                    </span>
                    <span>
                        {sales !== null && sales[0]?.address}
                    </span>
                    <span>
                        {sales !== null && sales[0]?.mobile_number}
                    </span>
                </div>
                <div className='details'>
                    <span><b>Bill NO: {sales !== null && sales[0]?.invoice}</b></span>
                    <span><b>Bill DATE :{sales !== null && sales[0]?.date}</b></span>
                </div>
            </div>
            <table>
                <thead className='header-row'>
                    <tr>
                        {headers.map((header) => (
                            <th key={header} style={{ border: '1px solid black', padding: '8px' }}>
                                {
                                    header
                                }

                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {sales !== null && sales?.map((row, index) => (
                        <tr key={index}>
                            <td style={{ border: '1px solid black', padding: '8px' }}>{index + 1}</td>
                            <td style={{ border: '1px solid black', padding: '8px' }}>{row.name_of_the_item}</td>
                            <td style={{ border: '1px solid black', padding: '8px' }}>{row.Hsncode}</td>
                            <td style={{ border: '1px solid black', padding: '8px' }}>{row.qty_supplied}</td>
                            <td style={{ border: '1px solid black', padding: '8px' }}>{row.unit_price}</td>
                            <td style={{ border: '1px solid black', padding: '8px' }}>{row.total_price}</td>
                            <td style={{ border: '1px solid black', padding: '8px' }}>{row.payment_type}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className='total-amount'>
                <div className='price'>
                    <span>{totalAmount()}</span>
                    <span>{Math.round(totalAmount())}</span>
                    <span>{Math.round(totalAmount())}</span>
                </div>
                <div className='label'>
                    <span><b>Gross Amount:</b></span>
                    <span><b>Round Off:</b></span>
                    <span><b>Net Amount:</b></span>
                </div>
            </div>
        </div>
    );
}

export default Auth(PrintablePage);
