import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Col, Modal, Row, Table, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Spinner from 'react-bootstrap/Spinner';
import Auth from './Auth';
function SalesInv() {
  const orgname = window.localStorage.getItem('org_name')
  const baseURL = window.location.origin;
  const [show, setShow] = useState(false);
  const [load, setload] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [tempTable, setTable] = useState([])
  const [item, setItem] = useState([]);
  const [rmdetails, setRmDetails] = useState([]);
  const [cvdetails, setCvDetails] = useState([]);
  const [visible, setVisible] = useState(true)
  const initialValues = {
    invoice: item.reduce((maxId, id) => {
      return maxId > id.invoice ? maxId : id.invoice + 1
    }, 1),
    rm_name: 0,
    cv_name: 0,
    qty_supplied: 0,
    org_name: orgname,
    payment_type: 'CASH',
    units_of_measurement: '',
    unit_price: 0,
    total_price: 0,
    gst_total: 0,
    basic_price: 0,
    qty_available: 0,
    address: '',
    gst_no: '',
    item_code: 0,
    mobile_number: '',
    tax_percentage: '',
    amount_recd: 0,
    grand_total_price: 0,
    grand_gst_total: 0,
    grand_unit_price: 0,
    grand_basic_price: 0,
    grand_amount_recd: 0,
    grand_qty_supplied: 0,
    creation_date: new Date(),
    date: `${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`
  }
  const [data, setData] = useState(initialValues)
  //salesBPDetails
  let urls = [`${baseURL}/api/Info/salesBPDetails/${orgname}`, `${baseURL}/api/Info/getCustomerDetails/${orgname}`]
  const handleClose = () => {
    setTable([])
    //setData(initialValues)
    setload(true)
    setShow(false)
    setClicked(false)
    window.sessionStorage.clear()
  };
  const handleShow = () => {
    setShow(true)
    setData(initialValues)
  }
  useEffect(() => {
    const requests = urls.map((url) => axios.get(url));
    setTable([])
    setData([])
    axios.all(requests)
      .then((res) => {
        if (res[0].status === 200) {
          setRmDetails(res[0].data)
        }
        if (res[1].status === 200) {
          setCvDetails(res[1].data)
        }
      })
      .catch((err) => {
        toast.warn(err.response.data, {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
    setload(false)
  }, [])
  useEffect(() => {
    axios.get(`${baseURL}/api/Transaction/getSalesinfo/${orgname}`)
      .then((res) => {
        if (res.status === 200) {
          setItem(res.data)
        }
      })
      .catch((err) => {
        toast.info('To see the Records Create New Record', {
          position: "bottom-left",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
    setload(false)
  }, [load])
  const onChangeMethod = (name, value) => {
    if (name === 'qty_supplied') {
      if (data.qty_available !== 0 && parseInt(value) <= data.qty_available) {
        setData({
          ...data, [name]: parseInt(value), ['total_price']: parseFloat(totalpriceValue(value))
        })
      }
      else {
        setData({
          ...data, [name]: 0, ['total_price']: 0
        })
      }
    }
    if (name === 'payment_type') {
      if (data.total_price > 0) {
        setData({
          ...data, [name]: value, ['amount_recd']: data.total_price
        })
      } else {
        setData({ ...data, [name]: value })
      }
    }
    if (name === 'invoice') {
      setData({ ...data, [name]: value })
    }
    if (name === 'rm_name' && (value !== 0 || value !== '')) {
      const { unit_price, units_of_measurement, tax_percentage, name_of_the_item, item_code, qty_available } = rmdetails.find(obj => obj.rm_id === parseInt(value))
      setData({
        ...data, ['rm_name']: parseInt(value), ['units_of_measurement']: units_of_measurement,
        ['unit_price']: unit_price, ['tax_percentage']: tax_percentage, ['name_of_the_item']: name_of_the_item,
        ['qty_available']: qty_available, ['qty_supplied']: initialValues.qty_supplied, ['item_code']: item_code,
        ['total_price']: initialValues.total_price
      })
    }
    if (name === 'cv_name' && (value !== 0 || value !== '')) {
      const { address, gst_no, mobile_number, name } = cvdetails.find(obj => obj.cv_id === parseInt(value))
      setData({ ...data, ['cv_name']: parseInt(value), ['cname']: name, ['address']: address, ['gst_no']: gst_no, ['mobile_number']: mobile_number })
    }
  }
  const totalpriceValue = (supplied) => {
    if (supplied === '' || supplied === 0) return 0
    else return parseFloat(parseInt(supplied) * data.unit_price).toFixed(2)
  }
  useEffect(() => {
    if (data.total_price !== 0 && (data.qty_supplied !== 0 || data.qty_supplied !== '')) {
      const value = parseInt(data.tax_percentage.substring(1, 3))
      const num = (data.total_price * value) / (100 + value)
      if ((data.payment_type === 'CASH' || data.payment_type === 'UPI')) {
        setData({
          ...data, ['gst_total']: parseFloat(num.toFixed(2)), ['basic_price']: basicpriceSet(num), ['amount_recd']: parseFloat(data.total_price)
        })
      }
      else if (data.payment_type === 'CREDIT') {
        setData({
          ...data, ['gst_total']: parseFloat(num.toFixed(2)), ['basic_price']: basicpriceSet(num), ['amount_recd']: 0
        })
      }
      else {
        setData({
          ...data, ['amount_recd']: 0
        })
      }
    }
    else {
      setData({
        ...data, ['gst_total']: 0, ['basic_price']: 0
      })
    }
  }, [data.qty_supplied, data.payment_type])
  const basicpriceSet = (price) => {
    if (price === 0 || price === '') return 0
    else return parseFloat(data.total_price - parseFloat(price)).toFixed(2)
  }
  const deleteRecord = (data) => {
    axios.delete(`${baseURL}/api/Transaction/salesInvDelete/${data}/${orgname}`)
      .then((res) => {
        if (res.status === 200) {
          setItem([])
          toast.success('Successfull.', {
            position: "bottom-left",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setload(true)
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
  }
  const getTotalValues = (values) => {
    let grand_total_price = 0
    let grand_gst_total = 0;
    let grand_unit_price = 0;
    let grand_basic_price = 0;
    let grand_amount_recd = 0;
    let grand_qty_supplied = 0;

    //console.log(values.length)
    values.forEach(values => {
      // console.log(values.unit_price, values.total_price)
      grand_total_price = values.total_price + grand_total_price
      grand_gst_total = values.gst_total + grand_gst_total
      grand_unit_price = values.unit_price + grand_unit_price
      grand_basic_price = values.basic_price + grand_basic_price
      grand_amount_recd = values.amount_recd + grand_amount_recd
      grand_qty_supplied = parseInt(values.qty_supplied) + grand_qty_supplied
    });
    return [Math.round(grand_total_price), parseFloat(grand_gst_total), parseFloat(grand_unit_price), parseFloat(grand_basic_price), Math.round(grand_amount_recd), parseInt(grand_qty_supplied)]
  }
  const submitForm = (event) => {
    event.preventDefault();
    const [grand_total_price, grand_gst_total, grand_unit_price, grand_basic_price, grand_amount_recd, grand_qty_supplied] = getTotalValues(tempTable)
    const cpytable = [...tempTable]
    const lastObj = cpytable[cpytable.length - 1]
    lastObj.grand_amount_recd = grand_amount_recd
    lastObj.grand_total_price = grand_total_price
    lastObj.grand_gst_total = grand_gst_total
    lastObj.grand_unit_price = grand_unit_price
    lastObj.grand_basic_price = grand_basic_price
    lastObj.grand_qty_supplied = grand_qty_supplied
    setTable(cpytable)
    //console.log(cpytable)
    window.sessionStorage.setItem('sales', JSON.stringify(tempTable))
    const printwindow = window.open('/print', '_blank')
    printwindow.onload = () => {
      printwindow.focus()
      setTimeout(() => {
        printwindow.print()
      }, 4000);
      printwindow.onafterprint = () => {
        printwindow.close()
        axios.post(`${baseURL}/api/Transaction/salesInfoInsertion`, tempTable)
          .then((res) => {
            console.log(tempTable)
            if (res.status === 200) {
              toast.success('Successfull.', {
                position: "bottom-left",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              handleClose();
            }
          }).catch((err) => {
            console.log(err)
            toast.error(err.response.data, {
              position: "bottom-left",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setClicked(false)
          })
      }
    }
  }
  const tempoValue = () => {
    console.log(data)
    if (data.tax_percentage === 'G0' || data.payment_type === 'CREDIT') {
      if (data.invoice !== '' && data.payment_type !== '' && data.date !== null && data.cv_name !== null && data.rm_name !== null && data.qty_supplied !== null && data.unit_price !== null && data.total_price !== null) {
        setTable([
          ...tempTable, data
        ])
        setData({
          ...data, ['basic_price']: initialValues.basic_price,
          ['gst_total']: initialValues.gst_total, ['item_code']: initialValues.item_code,
          ['qty_available']: initialValues.qty_available,
          ['qty_supplied']: initialValues.qty_supplied, ['rm_name']: initialValues.rm_name,
          ['total_price']: initialValues.total_price,
          ['unit_price']: initialValues.unit_price, ['units_of_measurement']: initialValues.units_of_measurement
        })
      }
    }
    else {
      if (Object.values(data).some((value) => value !== '' && value !== null) &&
        (data.grand_amount_recd === 0 && data.grand_basic_price === 0 && data.grand_gst_total == 0 && data.grand_qty_supplied === 0 && data.grand_total_price === 0 && data.grand_unit_price === 0)) {
        setTable([
          ...tempTable, data
        ])
        setData({
          ...data, ['basic_price']: initialValues.basic_price,
          ['gst_total']: initialValues.gst_total, ['item_code']: initialValues.item_code,
          ['qty_available']: initialValues.qty_available,
          ['qty_supplied']: initialValues.qty_supplied, ['rm_name']: initialValues.rm_name,
          ['total_price']: initialValues.total_price,
          ['unit_price']: initialValues.unit_price, ['units_of_measurement']: initialValues.units_of_measurement
        })
      } else {
        toast.error(`Few of the Details are not mentioned`, {
          position: "bottom-left",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      }
    }
  }
  useEffect(() => {
    if (tempTable.length > 0) {
      setVisible(false)
    }
    else {
      setVisible(true)
    }
  }, [data, tempTable])
  const deleteTableRecord = (index) => {
    const rows = [...tempTable];
    rows.splice(index, 1);
    setTable(rows);
  }
  const displayValue = (i) => {
    const { name_of_the_item } = rmdetails.find(obj => obj.rm_id === parseInt(i))
    return name_of_the_item
  }
  const displayCustValue = (i) => {
    const { name } = cvdetails.find(obj => obj.cv_id === parseInt(i))
    return name
  }
  return (
    <>
      <div>
        <Table striped bordered hover className='table container'>
          <thead>
            <tr>
              <th>S.NO</th>
              <th>Invoice</th>
              <th>{`Date(YYYY-MM-DD)`}</th>
              <th>RM Name</th>
              <th>Customer</th>
              <th>Quantity Supplied</th>
              <th>Total Price</th>
              <th>Payment Type</th>
              <th><Link><span className="material-symbols-outlined" onClick={() => { handleShow() }}>
                library_add
              </span></Link></th>

            </tr>
          </thead>
          <tbody>
            {item && item.map((i, index) => (
              <tr key={i.si_id}>
                <td>{index + 1}</td>
                <td>{i.invoice}</td>
                <td>{i.creation_date.split('T')[0]}</td>
                <td>{i.fg_name}</td>
                <td>{i.customer_name}</td>
                <td>{`${i.qty_supplied}`}<span>{i.uom}</span></td>
                <td>{i.total_price}</td>
                <td>{i.payment_type}</td>
              </tr>
            ))}
          </tbody>
        </Table >
        <Modal show={show} onHide={handleClose} size='lg'
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header closeButton>
            Add New Item
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col>
                  <Form.Label>Payment Type</Form.Label><br />
                  <Form.Check
                    inline
                    defaultChecked
                    label="CASH"
                    name="payment_type"
                    value="CASH"
                    disabled={tempTable.length !== 0 ? true : false}
                    onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }}
                    type='radio'
                  />
                  <Form.Check
                    inline
                    label="CREDIT"
                    name="payment_type"
                    value="CREDIT"
                    disabled={tempTable.length !== 0 ? true : false}
                    onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }}
                    type='radio'
                  />
                  <Form.Check
                    inline
                    label="UPI"
                    name="payment_type"
                    value="UPI"
                    disabled={tempTable.length !== 0 ? true : false}
                    onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }}
                    type='radio'
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>Invoice Number</Form.Label>
                  <Form.Control placeholder="Invoice Number" value={data.invoice}
                    name='invoice' type='number' required
                    disabled={true} />
                </Col>
                <Col>
                  <Form.Label>Date</Form.Label>
                  <Form.Group>
                    <DatePicker
                      selected={new Date()}
                      maxDate={new Date() - 1} required
                      disabled={true}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Label>Customer Details</Form.Label>
                  <Form.Select
                    name='cv_name'
                    required
                    disabled={tempTable.length !== 0 ? true : false}
                    value={data.cv_name}
                    // disabled={data.cv_name !== 0 ? true : false}
                    onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }}>
                    {cvdetails && <option value=''>Select the option</option>}
                    {cvdetails ?
                      cvdetails.map((i) => (
                        <option key={i.cv_id}
                          value={i.cv_id}>{i.name}</option>
                      ))
                      : <option>No Customer Details Found</option>}
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>Finished Goods</Form.Label>
                  <Form.Select
                    name='rm_name'
                    required
                    value={data.rm_name}
                    onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }}>
                    {rmdetails && <option value=''>Select the option</option>}
                    {rmdetails ?
                      rmdetails.map((i) => (
                        <option key={i.rm_id}
                          value={i.rm_id}>{i.name_of_the_item}</option>
                      ))
                      : <option>No Details of Raw Material</option>}
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Label>UOM</Form.Label>
                  <Form.Control disabled
                    name='units_of_measurement' type='text' required value={data.units_of_measurement} />
                </Col>
                <Col>
                  <Form.Label>GST Amount</Form.Label>
                  <Form.Control disabled
                    name='gst_total' type='number' required value={data.gst_total} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control name='qty_supplied' required value={data.qty_supplied !== 0 ? data.qty_supplied : ''} type='number'
                    min={0}
                    onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }} />
                  <Form.Label>{data.qty_available !== 0 && data.rm_name !== '' ? `Stock Availble is: ${data.qty_available}`
                    : data.qty_available === 0 && data.rm_name !== '' ? `Stock Availble is: ${data.qty_available}` : ``}
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Label>Unit Price</Form.Label>
                  <Form.Control disabled
                    name='unit_price' type='text' required value={data.unit_price} />
                </Col>
                <Col>
                  <Form.Label>Tax Percentage</Form.Label>
                  <Form.Control disabled
                    name='tax_percentage' type='text' required value={data.tax_percentage.substring(1, 3) + '%'} />
                </Col>
                <Col>
                  <Form.Label>Total Price</Form.Label>
                  <Form.Control disabled
                    name='total_price' type='number' required value={data.total_price} />
                </Col>
              </Row>
              <Row className='col-12'>
                <Col className='col-3'>
                  <Form.Label>Basic price</Form.Label>
                  <Form.Control disabled
                    name='basic_price' type='number' required value={data.basic_price} />
                </Col>
                {(data.payment_type === 'CASH' || data.payment_type === 'UPI') && <Col className='col-3'>
                  <Form.Label>Amount Received</Form.Label>
                  <Form.Control disabled
                    onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }}
                    name='amount_recd' type='number' required value={data.amount_recd !== 0 ? data.amount_recd : ''} />
                </Col>}
              </Row>
            </Form>
            {
              tempTable.length > 0 &&
              <div className='temptable'>
                <Table striped bordered hover className='table container'>
                  <thead>
                    <tr>
                      <th>Customer</th>
                      <th>Item</th>
                      <th>Quantity Supplied</th>
                      <th>UOM</th>
                      <th>Unit Price</th>
                      <th>Total Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      tempTable.map((i, index) => (
                        <tr key={index}>
                          <td>{displayCustValue(i.cv_name)}</td>
                          <td>{displayValue(i.rm_name)}</td>
                          <td>{i.qty_supplied}</td>
                          <td>{i.units_of_measurement}</td>
                          <td>{i.unit_price}</td>
                          <td>{i.total_price}</td>
                          <td><span className="material-symbols-outlined" onClick={() => deleteTableRecord(index)}>
                            delete_forever
                          </span></td>
                        </tr>

                      ))
                    }
                  </tbody>
                </Table>
              </div>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => tempoValue()}>Add</Button>

            {/* <Button variant='secondary' onClick={handleClose}>Close</Button> */}
            {clicked ? <Spinner animation="border" variant='primary' />
              : <Button variant='primary' disabled={visible} onClick={(event) => submitForm(event)}>Save</Button>}
          </Modal.Footer>
        </Modal>

        <ToastContainer />
      </div >
    </>
  )
}

export default Auth(SalesInv)
