import React, { useState, useEffect } from 'react'
import { Col, Row, Table, Form, Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import '../Asserts/css/createuser.css'
import axios from 'axios';
import Auth from './Auth';
function CreateUser() {
    const baseURL = window.location.origin
    const initialValues = {
        orgname: '',
        username: '',
        password: '',
        cpassword: '',
        mnumber: '',
        mailid: '',
        address: '',
        gst_no: '',
        creation_date: new Date()
    }
    const [clicked, setClicked] = useState(false)
    const [details, setDet] = useState(initialValues)
    const [disable, setDisable] = useState(true)
    useEffect(() => {
        if (Object.values(details).every((value) => value !== 0 && value !== '' && value !== null) && (details.password === details.cpassword)) {
            setDisable(false)
        }
        else {
            setDisable(true)
        }

    }, [details])
    const handleChange = (name, value) => {
        var regex = /^[a-zA-Z]+$/;
        setDet({
            ...details, [name]: value
        })
        if (name === 'mnumber' && value !== '') {
            value.match(regex) ? setDet({
                ...details, [name]: ''
            }) :
                setDet({
                    ...details, [name]: value
                })
        }
        if (name === 'mailid' && value.includes('@') && value.includes('.com')) {
            setDet({
                ...details, [name]: value
            })
        }
        if (name === 'cpassword') {
            if (details.password === value) {
                setDet({
                    ...details, ['cpassword']: value
                })
            }
        }
    }
    const handleSubmitUser = async (e) => {
        e.preventDefault();
        setClicked(true)
        await axios.post(`${baseURL}/api/User/userCreation`, details)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    setDet(initialValues)
                    toast.success('Successfull.', {
                        position: "bottom-left",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            })
            .catch((err) => {
                toast.error(err.response, {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
        setClicked(false)
    }
    return (
        <div className='form-div-create'>
            <Form className='form-create' autoCorrect='off' autoComplete='off' autoSave='off'>
                <Row className='field-head'>
                    <Col>
                        <Form.Group>
                            <Form.Label><span>Name</span></Form.Label>
                            <Form.Control
                                autoCorrect='off' autoComplete='off' autoSave='off'
                                value={details.username} required
                                type="text" className='input-recd' placeholder="Name"
                                name='username' onChange={(event) => { handleChange(event.target.name, event.target.value) }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Org Name</Form.Label>
                            <Form.Control
                                type="text" className='input-recd' placeholder="orgname"
                                name='orgname' value={details.orgname} required
                                onChange={(event) => { handleChange(event.target.name, event.target.value) }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='field-head'>
                    <Col>
                        <Form.Group>
                            <Form.Label><span>Mobile Number</span></Form.Label>
                            <Form.Control
                                autoCorrect='off' autoComplete='off' autoSave='off'
                                value={details.mnumber} required maxLength={10}
                                type="text" className='input-recd' placeholder="Mobile number"
                                name='mnumber' onChange={(event) => { handleChange(event.target.name, event.target.value) }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Mail ID</Form.Label>
                            <Form.Control
                                type="text" className='input-recd' placeholder="mail id"
                                name='mailid' value={details.mailid} required
                                onChange={(event) => { handleChange(event.target.name, event.target.value) }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='field-head'>
                    <Col>
                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password" className='input-recd' placeholder="password"
                                name='password' value={details.password} required
                                onChange={(event) => { handleChange(event.target.name, event.target.value) }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                type="password" className='input-recd' placeholder="confirm password"
                                name='cpassword' value={details.cpassword} required
                                onChange={(event) => { handleChange(event.target.name, event.target.value) }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='field-head'>
                    <Col>
                        <Form.Group>
                            <Form.Label>GST NO</Form.Label>
                            <Form.Control
                                type="text" className='input-recd' placeholder="GST NO"
                                name='gst_no'
                                required maxLength={15}
                                value={details.gst_no}
                                onChange={(event) => { handleChange(event.target.name, event.target.value.toUpperCase()) }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type='area' className='input-recd' placeholder="Address"
                                name='address'
                                as='textarea' rows={3}
                                value={details.address}
                                onChange={(event) => { handleChange(event.target.name, event.target.value) }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                {clicked ? <Spinner animation="border" variant='primary' /> :
                    <Button variant="primary" type="submit" className='submit' disabled={disable}
                        onClick={(e) => handleSubmitUser(e)}>
                        Submit
                    </Button>}
            </Form>
            <ToastContainer />
        </div>
    )
}

export default Auth(CreateUser)
