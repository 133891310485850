import React, { useEffect, useState } from 'react'
import { Button, Table, Modal, Form, Row, Col, FormSelect, Pagination } from 'react-bootstrap'
import axios from 'axios';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Auth from './Auth';
function ItemComponent() {
    const org_name = window.localStorage.getItem('org_name')
    const baseURL = window.location.origin;
    const [item, setItem] = useState([])
    const initialValues = {
        category: '',
        name_of_the_item: '',
        opening_stock: 0,
        sub_category: '',
        type_of_material: 'raw material',
        org_name: org_name,
        unit_price: 0,
        units_of_measurement: 'KGS',
        item_code: item.reduce((maxId, id) => {
            return maxId > id.item_code ? maxId : id.item_code + 1
        }, 1),
        tax_percentage: 'G0'
    }
    const tax_grid = ['G0', 'G5', 'G12', 'G18', 'G28']
    const units_of_measurement = ['KGS', 'GRMS', 'LTS', 'ML']
    const [clicked, setClicked] = useState(false);
    const [data, setData] = useState('')
    const [show, setShow] = useState(false);
    const [load, setload] = useState(true);
    const handleClose = () => {
        setload(true)
        setShow(false)
        setClicked(false)
    };
    const [cond, setCond] = useState(false);
    const [suggestion, setSuggestion] = useState([])
    useEffect(() => {
        axios.get(`${baseURL}/api/Info/GetRmDetails/${org_name}`)
            .then((res) => {
                if (res.status === 200) {
                    const list_rm = res.data.map(item => item.name_of_the_item);
                    setSuggestion(list_rm)
                    setItem(res.data)
                }
            })
            .catch((err) => {
                toast.info('Start Entering the Items', {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                console.log('No details received', err)

            })
        setload(false)
    }, [load])
    const handleShow = (data) => {
        setClicked(false)
        if (data.type_of_material === 'raw material') {
            setCond(true)
        }
        else {
            setCond(false)
        }
        setData(data)
        setShow(true)
    }
    const deleteRecord = (data) => {
        if (org_name !== null) {
            axios.delete(`${baseURL}/api/User/deleteItem/${data}/${org_name}`)
                .then((res) => {
                    if (res.status === 200) {
                        toast.success('Records Pulled Successfully.', {
                            position: "bottom-left",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setItem([])
                        setload(true)
                    }
                })
                .catch((err) => {
                    toast.error(err.response.data, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    console.log(err)
                })
        }
    }

    const onChangeMethod = (name, value) => {
        if (name === 'type_of_material' && value === 'raw material') {
            setCond(true)
            setData({ ...data, [data.unit_price]: 0 })
        }
        if (name === 'type_of_material' && value === 'finished goods') {
            setCond(false)
        }
        if (name === 'item_code') {
            setData({
                ...data, [name]: parseInt(value)
            })
        }
        if (name === 'unit_price') {
            setData({
                ...data, [name]: parseFloat(value)
            })
        }
        setData({
            ...data, [name]: value
        })
    }
    const submitForm = (event) => {
        event.preventDefault();
        if (data.org_name === org_name) {
            setClicked(true)
            axios.post(`${baseURL}/api/User/insertItemDetails`, data).then((res) => {
                if (res.status === 200) {
                    toast.success('Successful', {
                        position: "bottom-left",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                handleClose();
            }).catch((err) => {
                setClicked(false)
                toast.error(err.response.data, {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                console.log(err.response.data)
            })
        }
    }
    console.log(data)
    return (
        <>
            <Table striped bordered hover className='table container'>
                <thead>
                    <tr>
                        <th>Item Code</th>
                        <th>category</th>
                        <th>sub_category</th>
                        <th>type_of_material</th>
                        <th>name_of_the_item</th>
                        <th>unit_price</th>
                        <th>Tax Percentage</th>
                        <th><Link><span className="material-symbols-outlined" onClick={() => handleShow(initialValues)}>
                            library_add
                        </span></Link></th>

                    </tr>
                </thead>
                <tbody>
                    {item && item.map((i) => (
                        <tr key={i.rm_id}>
                            <td>{i.item_code}</td>
                            <td>{i.category}</td>
                            <td>{i.sub_category}</td>
                            <td>{i.type_of_material}</td>
                            <td>{i.name_of_the_item}</td>
                            <td>{i.unit_price === 0 ? 'null' : i.unit_price}</td>
                            <td>{i.tax_percentage}</td>
                            <td>
                                <Link><span className="material-symbols-outlined" onClick={() => handleShow(i)}>
                                    edit_note
                                </span></Link><Link><span className="material-symbols-outlined" onClick={() => deleteRecord(i.rm_id)}>
                                    delete_forever
                                </span></Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table >
            <Modal show={show} onHide={handleClose}
                size="ms"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <b style={{ color: "red", fontSize: 25 }}>Item Master</b>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Label>Select the Material Type</Form.Label>
                                <Form.Select defaultValue={data.type_of_material}
                                    name='type_of_material'
                                    onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }}>
                                    <option value='raw material'>Raw Material</option>
                                    <option value='finished goods'>Finished Goods</option>
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label>Select the Tax Grid</Form.Label>
                                <FormSelect defaultValue={data.tax_percentage}
                                    name='tax_percentage' onChange={(e) => onChangeMethod(e.target.name, e.target.value)}>
                                    {tax_grid.map((x, y) => (
                                        <option key={y} value={x}>{x}</option>
                                    ))}

                                </FormSelect>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>category</Form.Label>
                                <Form.Control placeholder="Enter Category" defaultValue={data.category}
                                    name='category' onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }} />
                            </Col>
                            <Col>
                                <Form.Label>sub category</Form.Label>
                                <Form.Control placeholder="Enter Sub Category" defaultValue={data.sub_category}
                                    name='sub_category'
                                    onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>Name Of The Item</Form.Label>
                                <Form.Control placeholder="Enter Name Of the item" defaultValue={data.name_of_the_item}
                                    autoComplete="off"
                                    name='name_of_the_item' type='search' list='list'
                                    onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }} />
                                <datalist id="list">
                                    {item.map((d, index) => <option key={index} value={d.name_of_the_item} />)}
                                </datalist>
                            </Col>
                            <Col>
                                <Form.Label>Units Of Measurement</Form.Label>
                                <Form.Select defaultValue={data.units_of_measurement}
                                    name='units_of_measurement' onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }}
                                >
                                    {units_of_measurement.map((x, y) => (
                                        <option key={y} value={x}>{x}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>Open Stock</Form.Label>
                                <Form.Control placeholder="Enter Opening Stock" defaultValue={data.opening_stock}
                                    name='opening_stock' type='number'
                                    onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }} />
                            </Col>
                            <Col>
                                <Form.Label>Unit Price</Form.Label>
                                <Form.Control placeholder="Enter Unit Price" defaultValue={data.unit_price}
                                    name='unit_price' disabled={cond} type='number'
                                    onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }} />
                            </Col>
                            <Row>
                                <Col>
                                    <Form.Label>Item Code</Form.Label>
                                    <Form.Control placeholder="Item Code" defaultValue={data.item_code} disabled
                                        name='item_code' type='text' />
                                </Col>
                            </Row>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>

                    {clicked ? <Spinner animation="border" variant='primary' />
                        : <Button variant='primary' onClick={(event) => submitForm(event)}>Save</Button>}
                    <Button variant='secondary' onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal >
            <ToastContainer />
        </>
    )
}

export default Auth(ItemComponent)
