import React, { useState, useEffect } from 'react'
import '../Asserts/css/paymentreceipt.css'
import axios from 'axios'
import { Col, Row, Table, Form, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import Auth from './Auth';

function PaymentReceipt() {
    const [payDetails, setPayDetails] = useState([])
    const [itemDetails, setItemDetails] = useState([])
    const [inv, setInv] = useState(0)
    const [custdet, setcustDet] = useState([])
    const [item, setItem] = useState([])
    const orgname = window.localStorage.getItem('org_name')
    const baseURL = window.location.origin;
    let urls = [`${baseURL}/api/Info/getPaymentInv/${orgname}`, `${baseURL}/api/Info/getItemInv/${orgname}`]
    const initialValues = {
        id: 0,
        cv_id: 0,
        invoice: 0,
        orgname: orgname,
        payment_type: 'CREDIT',
        total_price: 0,
        amount_recd: '',
        balance_amount: 0,
        creation_date: new Date(),//`${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`,
        payment_source: 'CASH',
        cheque_no: ''
    }
    const [form, setForm] = useState(initialValues)
    useEffect(() => {
        const requests = urls.map((url) => axios.get(url));
        axios.all(requests)
            .then((res) => {
                if (res[0].status === 200 && res[1].status === 200) {
                    setPayDetails(res[0].data)
                    setItemDetails(res[1].data)
                }
            })
            .catch((err) => {
                toast.error(err.response.data, {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
    }, [])
    const onChangeMethod = (name, value) => {
        if (name === 'payment_source' || name === 'cheque_no') {
            setForm({
                ...form, [name]: value
            })
        }
        else if (name === 'amount_recd') {
            if (parseInt(value) <= custdet[0].balance_amount && value !== '') {
                // setAmt(parseInt(value))
                setForm({
                    ...form, ['total_price']: custdet[0].balance_amount,
                    [name]: parseInt(value),
                    ['balance_amount']: parseInt(custdet[0].balance_amount) - parseInt(value)
                })
            } else {
                setForm({
                    ...form, [name]: ''
                })
            }
        }
        else if (name === 'invoice') {
            setInv(parseInt(value))
            setForm({
                ...form, ['invoice']: parseInt(value),
            })
        }
    }
    const fetchDetails = () => {
        if (inv !== 0) {
            const details = itemDetails.filter(item => {
                return item.invoice === parseInt(inv)
            })
            const cust = payDetails.filter(item => {
                return item.invoice === parseInt(inv)
            })
            console.log(cust, details)
            if (details.length !== 0 && cust.length !== 0) {
                setItem(details)
                setcustDet(cust)
                setForm({
                    ...form,
                    ['cv_id']: parseInt(cust[0].cv_id),
                    ['id']: parseInt(cust[0].id)
                })
            } else {
                toast.error(`No Details Found with This Invoice:${inv}`, {
                    position: "bottom-left",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }
    const defaultBehavior = (event) => {
        const wheelKeys = ['ArrowUp', 'ArrowDown'];
        if (wheelKeys.includes(event.key)) {
            event.preventDefault();
        }
    }
    const submitAmount = (event) => {
        event.preventDefault();
        window.print();
        window.onafterprint = () => {
            axios.post(`${baseURL}/api/Transaction/paymentInsertion`, form)
                .then((res) => {
                    if (res.status === 200) {
                        toast.success('Successfull.', {
                            position: "bottom-left",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setForm(initialValues)
                    }
                })
                .catch((err) => {
                    toast.error(err.response.data, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                })
        }
    }
    return (
        <>
            <div className='container'>
                <div className='view-container'>
                    <span className='label-receipt'><b>Credit Payment Receipt</b></span>
                    <div className='view-container-select'>
                        <Form>
                            <Row>
                                <Form.Label className='print-hide'>Select Invoice:</Form.Label><br />
                                <Form.Select
                                    className='print-hide'
                                    name='invoice'
                                    required
                                    value={inv}
                                    onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }}
                                >{payDetails && <option value={''} defaultChecked>Select the option</option>}
                                    {payDetails.length !== 0 ?
                                        payDetails.map((i, index) => (
                                            <option key={index} value={i.invoice}>
                                                {i.invoice}
                                            </option>
                                        )) : <option>No Details Found</option>}
                                </Form.Select>
                            </Row>
                            <Button className='btn print-hide' disabled={inv !== 0 ? false : true} onClick={() => fetchDetails()}>Go</Button>
                        </Form>
                    </div>
                    <div>
                    </div>
                </div>
                <div className='date-receipt'>
                    {inv !== 0 && <><span><b>Invoice: </b>{inv}</span><br /></>}
                    <span><b>Date: </b>{`${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`}</span></div>
                {custdet.length !== 0 && <div className='cusdetails'>
                    <div>
                        <span><b><u>Customer Details:</u></b></span>
                        {custdet?.map((i, index) => (
                            <div key={index}>
                                <span>
                                    {i.name}
                                </span><br />
                                <span>
                                    {i.address}
                                </span><br />
                                <span>
                                    {i.mobile_number}
                                </span><br />
                                <span>
                                    {i.gst_no}
                                </span><br />
                            </div>
                        ))}
                    </div>
                    <div>
                        <Table className='striped bordered hover'>
                            <thead>
                                <tr>
                                    <th>Item Details</th>
                                    <th>Date</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {item && item.map((i, index) => (
                                    <tr key={index}>
                                        <td className='citems'>
                                            <span><b>Invoice:</b>{i.invoice}</span><br />
                                            <span><b>Name:</b>{i.fg_name.toUpperCase()}</span><br />
                                            <span><b>Quantity:</b>{`${i.qty_supplied} ${i.uom}`}</span><br />
                                        </td>
                                        <td className='citems'>
                                            <span>{i.creation_date.split('T')[0]}</span>
                                        </td>
                                        <td className='citems'>
                                            <span>{i.total_price}</span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table><br />
                    </div>
                    <div className='div-amount'>
                        <div className='amount'>
                            <span><b>Total Amount:</b>{
                                custdet.map((i, index) => (
                                    <span key={index}>{i.balance_amount}</span>
                                ))
                            }</span>
                        </div>
                        <div className='amount'>
                            <Form.Label className='print-hide'><b>Amount Received:</b></Form.Label>
                            <Form.Control disabled={custdet[0]?.balance_amount !== 0 ? false : true}
                                className='input-recd-number print-hide'
                                name='amount_recd' type='number' required value={form.amount_recd !== 0 ? form.amount_recd : ''}
                                onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }}
                                onKeyDown={(event) => defaultBehavior(event)}
                                min={0}
                                onWheel={(event) => defaultBehavior(event)} />
                            {form.amount_recd !== '' && <span><b>Amount Received: </b>{form.amount_recd}</span>}
                            {(form.amount_recd !== '') && <span><b>Balance Amount: </b>{(custdet[0].balance_amount - parseInt(form.amount_recd))}</span>}
                            <Col>
                                <Form.Label className='print-hide'>Payment Source</Form.Label><br />
                                <Form.Check className='select-source print-hide'
                                    label="CASH"
                                    name="payment_source"
                                    defaultChecked
                                    value="CASH"
                                    onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }}
                                    type='radio'
                                />
                                <Form.Check className='select-source print-hide'
                                    label="CHEQUE"
                                    name="payment_source"
                                    value="CHEQUE"
                                    onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }}
                                    type='radio'
                                />
                                <Form.Check className='select-source print-hide'
                                    label="UPI"
                                    name="payment_source"
                                    value="UPI"
                                    onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }}
                                    type='radio'
                                />
                            </Col>
                            {form.payment_source !== '' && <span><b>Payment Source: </b>{form.payment_source}</span>}
                            {form.payment_source === 'CHEQUE' &&
                                <>
                                    <Form.Label className='print-hide'><b>Cheque Number:</b></Form.Label>
                                    <Form.Control disabled={form.payment_source === 'CHEQUE' ? false : true}
                                        className='input-recd print-hide'
                                        maxLength={20}
                                        name='cheque_no' type='text' required={form.payment_source === 'CHEQUE' ? true : false}
                                        value={form.cheque_no}
                                        onChange={(e) => { onChangeMethod(e.target.name, e.target.value) }} />
                                </>}
                            {form.cheque_no !== '' && <span><b>Cheque NO: </b>{form.cheque_no}</span>}
                            <Button className='print-hide' disabled={((form.amount_recd !== '') ? (form.payment_source === 'CHEQUE') ? (form.cheque_no !== '') ? false : true : false : true)} onClick={(event) => submitAmount(event)}>Submit</Button>

                        </div>
                    </div>
                </div>}
            </div >
            <ToastContainer />
        </>
    )
}

export default Auth(PaymentReceipt)
