import { createSlice } from "@reduxjs/toolkit";
const UserSlice = createSlice({
    name: 'userSlice',
    initialState: [],
    reducers: {
        addUser(state, action) {
            state.push(action.payload)
        },
        clearUser(state, action) {
            state.push([])
        }
    }
})

export { UserSlice }
export const { addUser, clearUser } = UserSlice.actions