import React, { useState, useEffect } from 'react'
import { Modal, Table, Button, Form, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../Asserts/css/mastercustomer.css'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import Auth from './Auth'
function MasterCustomer() {
    const initialValues = {
        "type": 'Customer',
        "name": '',
        "mobile_number": 0,
        "org_name": window.localStorage.getItem('org_name'),
        "gst_no": '',
        "address": ''
    }
    const baseURL = window.location.origin;
    const [reload, setReload] = useState(true)
    const org_name = window.localStorage.getItem("org_name")
    const [item, setItem] = useState([])
    const [data, setData] = useState('')
    const [show, setShow] = useState(false);
    useEffect(() => {
        axios.get(`${baseURL}/api/CustomerVendor/getAllCvDetails/${org_name}`)
            .then((res) => {
                if (res.status === 200) {
                    setItem(res.data)
                    setReload(false)
                }
            })
            .catch((err) => {
                console.log(err.response.data, err)

            })
        setReload(false)
    }, [reload])
    const handleClose = () => {
        setShow(false)
        setReload(true)
    };
    const handleShow = (data) => {
        setData(data)
        setShow(true)
    };
    const onChangeMethod = (name, value) => {
        setData({
            ...data, [name]: value
        })
    }
    const submitForm = (event) => {
        event.preventDefault();
        if (data.org_name === org_name) {
            axios.post(`${baseURL}/api/CustomerVendor/cvinsertion`, data)
                .then((res) => {
                    if (res.status === 200) {
                        toast.success('Successfull', {
                            position: "bottom-left",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        handleClose();
                    }
                }).catch((err) => {
                    console.log(err)
                    toast.error(err.response.data, {
                        position: "bottom-left",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                })
        }
    }
    const deleteRecord = (id) => {
        //event.preventDefault();
        if (org_name !== null) {
            axios.delete(`${baseURL}/api/CustomerVendor/deleteItem/${id}/${org_name}`)
                .then((res) => {
                    if (res.status === 200) {
                        setItem([])
                        toast.success('Successfull', {
                            position: "bottom-left",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        console.log(res)
                        handleClose()
                    }
                })
                .catch((err) => {
                    toast.error(err.response.data, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                })
            setReload(true)
        }
    }
    return (
        <div>
            <Table striped bordered hover className='table container'>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Type</th>
                        <th>Name</th>
                        <th>Mobile Number</th>
                        <th>GST No</th>
                        <th><Link><span className="material-symbols-outlined" onClick={() => handleShow(initialValues)}>library_add</span></Link></th>
                    </tr>
                </thead>
                <tbody>
                    {item && item.map((i, x) => (
                        <tr key={i.cv_id}>
                            <td>{x + 1}</td>
                            <td>{i.type}</td>
                            <td>{i.name}</td>
                            <td>{i.mobile_number}</td>
                            <td>{i.gst_no ? i.gst_no : 'Not Defined'}</td>
                            <td><Link>
                                <span className="material-symbols-outlined" onClick={() => handleShow(i)}>edit_note</span>
                            </Link><Link><span className="material-symbols-outlined" onClick={() => deleteRecord(i.cv_id)}>
                                delete_forever
                            </span></Link></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal show={show} onHide={handleClose}
                size="ms"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <b style={{ color: "red", fontSize: 25 }}>Cust/Vendor Master</b>
                </Modal.Header>
                <Modal.Body>
                    <Form autoComplete='off' autoSave='off'>
                        <Row>
                            <Col>
                                <Form.Label>Select the Customer/Master</Form.Label>
                                <Form.Select value={data.type} required
                                    onChange={(e) => onChangeMethod(e.target.name, e.target.value)}
                                    name='type'>
                                    <option value='Customer'>Customer</option>
                                    <option value='Vendor'>Vendor</option>
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label>Name</Form.Label>
                                <Form.Control placeholder='Enter the Name'
                                    autoComplete='off' autoSave='off'
                                    value={data.name} name='name' required
                                    onChange={(e) => onChangeMethod(e.target.name, e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>GST NO</Form.Label>
                                <Form.Control placeholder='Enter the GST Number'
                                    autoComplete='off' autoSave='off'
                                    value={data.gst_no} name='gst_no'
                                    required maxLength={15}
                                    onChange={(e) => onChangeMethod(e.target.name, e.target.value.toUpperCase())}
                                />
                            </Col>
                            <Col>
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control placeholder='Enter the Mobile Number'
                                    autoComplete='off' autoSave='off'
                                    value={data.mobile_number === 0 ? '' : data.mobile_number}
                                    required maxLength={10} name='mobile_number' type='number'
                                    onChange={(e) => onChangeMethod(e.target.name, e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>Enter Address</Form.Label>
                                <Form.Control placeholder='Enter the Address' name='address'
                                    autoComplete='off' autoSave='off'
                                    value={data.address}
                                    onChange={(e) => onChangeMethod(e.target.name, e.target.value)}
                                    as='textarea' rows={5} required />
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='primary' onClick={(event) => submitForm(event)}>Save</Button>
                    <Button variant='secondary' onClick={() => handleClose()}>Close</Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </div>
    )
}

export default Auth(MasterCustomer)
